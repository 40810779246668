// extracted by mini-css-extract-plugin
export var ticket = "ticket-module--ticket--uCVV8";
export var ticketContainer = "ticket-module--ticketContainer--SKyPp";
export var ticketContainerSeasonPass = "ticket-module--ticketContainerSeasonPass--uqAwv";
export var ticketSeasonTicket = "ticket-module--ticketSeasonTicket--3FzRX";
export var ticket__bottomButtons = "ticket-module--ticket__bottomButtons--Ahb8h";
export var ticket__center = "ticket-module--ticket__center--514NC";
export var ticket__content = "ticket-module--ticket__content--LpNuz";
export var ticket__contentWithButton = "ticket-module--ticket__contentWithButton--A9xRg";
export var ticket__league = "ticket-module--ticket__league--wqXAJ";
export var ticket__left = "ticket-module--ticket__left--Cda2L";
export var ticket__logo = "ticket-module--ticket__logo--LkGpc";
export var ticket__logoContainer = "ticket-module--ticket__logoContainer--TdGWZ";
export var ticket__logos = "ticket-module--ticket__logos--J6E-8";
export var ticket__logosContainer = "ticket-module--ticket__logosContainer--NIHrI";
export var ticket__right = "ticket-module--ticket__right--3ao-x";
export var ticket__rightButtons = "ticket-module--ticket__rightButtons--ROER2";
export var ticket__statusContainer = "ticket-module--ticket__statusContainer--jVG4q";
export var ticket__statusIndicator = "ticket-module--ticket__statusIndicator--xWxa7";
export var ticket__statusIndicatorActive = "ticket-module--ticket__statusIndicatorActive--gjfN6";
export var ticket__statusIndicatorGiven = "ticket-module--ticket__statusIndicatorGiven--Xcotc";
export var ticket__statusIndicatorUsed = "ticket-module--ticket__statusIndicatorUsed--nPhyt";
export var ticket__teamTitle = "ticket-module--ticket__teamTitle--T0ggx";
export var ticket__titlesContainer = "ticket-module--ticket__titlesContainer--gHfV6";