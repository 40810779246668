import React, { useContext, useEffect, useState } from 'react';

import * as style from 'styles/components/account/accountLayout.module.scss';
import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import fetchAPI from 'data/api';
import { AXS_URLS, AXS_EMAIL_ATTRIBUTES } from 'data/constants';
import useSeasons from 'data/queries/useSeasons';
import useAccountBanners from 'data/queries/useAccountBanners';
import useHammarbyClub from 'data/queries/useHammarbyClub';
import AccountLayout from 'components/account/Layout';
import AxsEmailRegister from 'components/account/AxsEmailRegister';
import Ticket from 'components/account/TicketItem';
import AccountBanner from 'components/account/Banner';
import Loader from 'components/utils/Loader';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const AccountTickets = ({ pageContext }) => {
    const { lang } = useContext(LangContext);
    const { isSignedIn, user } = useAuth();

    const club = useHammarbyClub();
    const banners = useAccountBanners();
    const seasons = useSeasons();

    const [filteredTeam, setFilteredTeam] = useState({});
    const [filteredBanners, setFilteredBanners] = useState([]);
    const [seasonStart, setSeasonStart] = useState(null);

    const [allTickets, setAllTickets] = useState(null);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [isNoTickets, setIsNoTickets] = useState(null);
    const [isErrorLoadingTickets, setIsErrorLoadingTickets] = useState(false);
    const [loading, setLoading] = useState(false);

    const [axsUrl, setAxsUrl] = useState(AXS_URLS.MEN);

    // Get the start date of the ongoing season (or last started season)
    useEffect(() => {
        const now = new Date();
        const currentSeason = seasons.find(item => new Date(item.start) <= now);

        setSeasonStart(new Date(currentSeason.start));
    }, [seasons]);

    // Get the teams filters and select the first one
    useEffect(() => {
        const teams = club.teams.filter(item => (
            item.title && item.title[lang.translationKey] && item.allowTicketsSales
        ));

        let selectedTeam = teams[0];

        if (typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);

            if (urlParams.get('selectedTeam') !== null) {
                selectedTeam = teams.find((item) => item._id === urlParams.get('selectedTeam')) || teams[0];
            }
        }

        setFilteredTeam(selectedTeam);
    }, [club, lang.translationKey]);

    // Get the tickets and link them to the matches
    useEffect(() => {
        if (!isSignedIn) return;

        setLoading(true);

        fetchAPI(process.env.GATSBY_API_TICKETS, user?.signInUserSession.idToken.jwtToken)
            .then((tickets) => {
                const availableTickets = tickets
                    .map(ticket => {
                        if (!ticket.event.matchId) return null;

                        const match = pageContext.allMatches.find(m => m._id === ticket.event.matchId);
                        if (!match) return null;

                        return {
                            ...ticket,
                            matchData: {
                                team: match.team,
                                local: match.local,
                                external: match.external,
                                location: match.location,
                                date: match.startEventDate,
                            },
                        };
                    })
                    .filter(ticket => ticket && new Date(ticket.matchData.date) >= seasonStart);

                setAllTickets(availableTickets);
                setLoading(false);
            })
            .catch((error) => {
                console.warn(error);
                setIsErrorLoadingTickets(true);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignedIn, seasonStart]);

    // Manage tickets showed with the team filter
    useEffect(() => {
        if (!allTickets) return;

        if (allTickets.length === 0) {
            setIsNoTickets(true);
            return;
        }

        const filtered = allTickets
            .filter(ticket => ticket.matchData.team._id === filteredTeam._id)
            .sort((a, b) => new Date(a.matchData.date) - new Date(b.matchData.date));

        setFilteredTickets(filtered);
        setIsNoTickets(filtered.length === 0);
        setAxsUrl(filteredTeam.axsType === 'men' ? AXS_URLS.MEN : AXS_URLS.WOMEN);
    }, [filteredTeam, allTickets]);

    // Manage the banners showed with the team filter
    useEffect(() => {
        const filtered = banners?.filter(bannerForFilter => (
            bannerForFilter.pages.some(page => page.slug.current === pageContext.page.slug.current) &&
            bannerForFilter.teams.some(team => team._id === filteredTeam._id)
        ));

        setFilteredBanners(filtered);
    }, [filteredTeam, banners, pageContext]);

    return (
        <AccountLayout pageContext={pageContext}>
            <ul className="tabs tabs--NoScrollDesk">
                {club.teams
                    .filter(
                        (item) =>
                            item.title &&
                            item.title[lang.translationKey] &&
                            item.allowTicketsSales,
                    )
                    .map((item) => (
                        <li key={item._id}>
                            <button
                                onClick={() => setFilteredTeam(item)}
                                className={filteredTeam._id === item._id ? 'active' : ''}
                                type="button"
                            >
                                {item.title[lang.translationKey]}
                            </button>
                        </li>
                    ))}
            </ul>

            {/* Display connect account */}
            {isSignedIn && !user.attributes[AXS_EMAIL_ATTRIBUTES[filteredTeam.axsType]] && (
                <>
                    {filteredTeam.axsType === 'men' &&
                        <AxsEmailRegister teamType="men" teamId={filteredTeam._id} />
                    }
                    {filteredTeam.axsType === 'women' &&
                        <AxsEmailRegister teamType="women" teamId={filteredTeam._id} />
                    }
                </>
            )}

            {/* Display tickets */}
            {isSignedIn && user.attributes[AXS_EMAIL_ATTRIBUTES[filteredTeam.axsType]] && (
                <>
                    {loading && <Loader message={getFormattedMessage('account.ticket.loading', lang)} />}

                    {filteredTickets.length !== 0 && (
                        <div className={style.account__content__list}>
                            <ul>
                                {filteredTickets.map(item => (
                                    <li key={item.id}>
                                        <Ticket ticket={item} isHorizontalFormat axsUrl={axsUrl} />
                                    </li>
                                ))}
                            </ul>

                            <div className={style.account__content__list__buyTicket}>
                                <a href={axsUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('match.buyTicket', lang)}>
                                    <FormattedMessage id="match.buyTicket" />
                                </a>
                            </div>
                        </div>
                    )}

                    {/* Display no tickets bought message */}
                    {isNoTickets && (
                        <div className={style.account__emptyTicketsBox}>
                            <p>
                                <FormattedMessage id="account.ticket.noTicket" />
                                <a href={axsUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('match.buyTicket', lang)}>
                                    <FormattedMessage id="match.buyTicket" />
                                </a>
                            </p>
                        </div>
                    )}

                    {/* Display Error message */}
                    {isErrorLoadingTickets && (
                        <div className={style.account__errorTicketsBox}>
                            <p>
                                <span><FormattedMessage id="account.ticket.errorLoadingTickets" /></span>
                                <a href={axsUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.ticket.seeTicketsInAxs', lang)}>
                                    <FormattedMessage id="account.ticket.seeTicketsInAxs" />
                                </a>
                            </p>
                        </div>
                    )}
                </>
            )}

            {/* Display banners */}
            {filteredBanners && filteredBanners.map(banner => (
                <div key={banner.id}>
                    <AccountBanner banner={banner} />
                </div>
            ))}
        </AccountLayout>
    );
};

export default AccountTickets;
