import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/account/ticket.module.scss';
import { LangContext } from 'context/LangContext';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

// Todo waiting for definitive status map from SEF API
const STATUS_MAP = {
    'Valid': { translation: 'account.ticket.status.active', style: style.ticket__statusIndicatorActive },
    'Used': { translation: 'account.ticket.status.used', style: style.ticket__statusIndicatorUsed },
    'Reserved': { translation: 'account.ticket.status.reserved', style: style.ticket__statusIndicatorUsed },
    'GivenAway': { translation: 'account.ticket.status.givenAway', style: style.ticket__statusIndicatorGiven },
};

const Ticket = ({ ticket, isSeasonPass, axsUrl }) => {
    const { lang } = useContext(LangContext);
    const date = new Date(ticket.matchData.date);
    const formattedDate = date.toLocaleDateString(lang.iso, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
    const formattedHour = date.toLocaleTimeString(lang.iso, {
        timeStyle: 'short',
    });

    const ticketMainClass = `
        ${style.ticket} 
        ${style.ticketContainer}
        ${isSeasonPass && style.ticketSeasonTicket}
        ${isSeasonPass && style.ticketContainerSeasonPass}
    `;

    const givenAwayButtonClass = ticket.status === 'GivenAway' ? 'inactive' : '';

    return (
        <div className={ticketMainClass}>
            <div className={`${style.ticket__content} ${style.ticket__contentWithButton}`}>

                <div className={style.ticket__left}>
                    {ticket.league && (
                        <p className={style.ticket__league}>{ticket.league}</p>
                    )}

                    <p className="text-lg text-semibold">{formattedDate}</p>
                    <p className="text-xs text-medium uppercase text-darker">
                        {ticket.matchData.location} - {formattedHour}
                    </p>

                    {ticket.status && (
                        <div className={style.ticket__statusContainer}>
                            <FormattedMessage id={STATUS_MAP[ticket.status].translation} />
                            <span className={`${style.ticket__statusIndicator} ${STATUS_MAP[ticket.status].style}`} />
                        </div>
                    )}
                </div>

                <div className={style.ticket__center}>
                    <div className={style.ticket__logos}>
                        <div className={style.ticket__logosContainer}>
                            <div className={style.ticket__logoContainer}>
                                <div className={style.ticket__logo}>
                                    {ticket.matchData.local.image && (
                                        <ImageGatsby
                                            {...ticket.matchData.local.image}
                                            alt={ticket.matchData.local.title}
                                            title={ticket.matchData.local.title}
                                            width={60}
                                        />
                                    )}
                                </div>

                                <span className={`${style.ticket__teamTitle} text-semibold`}>
                                    {ticket.matchData.local.title}
                                </span>
                            </div>

                            <div>
                                <p className="text-xs text-medium uppercase">&mdash;</p>
                            </div>

                            <div className={style.ticket__logoContainer}>
                                <div className={style.ticket__logo}>
                                    {ticket.matchData.external.image && (
                                        <ImageGatsby
                                            {...ticket.matchData.external.image}
                                            alt={ticket.matchData.external.title}
                                            title={ticket.matchData.external.title}
                                            width={60}
                                        />
                                    )}
                                </div>

                                <span className={`${style.ticket__teamTitle} text-semibold`}>
                                    {ticket.matchData.external.title}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={style.ticket__rightButtons}>
                        {!isSeasonPass && axsUrl && (
                            <a href={axsUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.ticket.manage', lang)}>
                                <FormattedMessage id="account.ticket.manage" />
                            </a>
                        )}

                        {ticket.ticketUrl && (
                            <a href={ticket.ticketUrl} className={`cta-white ${givenAwayButtonClass}`}
                               target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.ticket.showTicket', lang)}>
                                <FormattedMessage id="account.ticket.showTicket" />
                            </a>
                        )}
                    </div>
                </div>
            </div>

            <div className={style.ticket__bottomButtons}>
                {!isSeasonPass && axsUrl && (
                    <a href={axsUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.ticket.manage', lang)}>
                        <FormattedMessage id="account.ticket.manage" />
                    </a>
                )}

                {ticket.ticketUrl && (
                    <a href={ticket.ticketUrl} className={`cta-white ${givenAwayButtonClass}`}
                       target="_blank" rel="noreferrer" aria-label={getFormattedMessage('account.ticket.showTicket', lang)}>
                        <FormattedMessage id="account.ticket.showTicket" />
                    </a>
                )}
            </div>
        </div>
    );
};

export default Ticket;
