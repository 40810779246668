import React, { useContext, useEffect, useState } from 'react';

import * as style from 'styles/components/account/axsEmailRegister.module.scss';
import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const apiTicketMapping = {
    men: 'MEN_TICKETS',
    women: 'WOMEN_TICKETS',
};

const AxsEmailRegister = ({ teamType, teamId }) => {
    const { lang } = useContext(LangContext);
    const { user } = useAuth();

    const axsUrl = teamType === 'women'
        ? 'https://hammarbydam.ebiljett.nu/Account'
        : 'https://hammarbyherr.ebiljett.nu/Account/AxsSdk';

    const [isPending, setIsPending] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (typeof sessionStorage === 'undefined' || !sessionStorage.getItem(`pendingEmail${teamType}`)) return;

        setIsPending(true);
    }, [setIsPending, teamType]);

    const sendRequest = (data) => {
        const requestUrl = process.env.GATSBY_ACCOUNT_REGISTER_EMAIL;
        const requestHeaders = new Headers();
        requestHeaders.append('Authorization', user?.signInUserSession.idToken.jwtToken);
        requestHeaders.append('Content-Type', 'application/json');

        const requestParams = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(data),
        };

        fetch(requestUrl, requestParams)
            .then((res) => {
                if (res.status === 200) {
                    if (typeof sessionStorage !== 'undefined') {
                        sessionStorage.setItem(`pendingEmail${teamType}`, data.email);
                    }
                    setIsPending(true);
                } else {
                    setIsError(true);
                }
            })
            .catch((error) => {
                console.warn('Request failed', error);
                setIsError(true);
            });
    };

    const handleFormSubmit = (evt) => {
        evt.preventDefault();
        setIsError(false);

        const formData = new FormData(evt.target);
        const jsonData = {};
        formData.forEach((value, key) => {
            jsonData[key] = value;
        });

        sendRequest(jsonData);
    };

    const handleFormReset = () => {
        setIsPending(false);
        if (typeof sessionStorage !== 'undefined') {
            sessionStorage.setItem(`pendingEmail${teamType}`, '');
        }
    };

    return (
        <div className={style.container}>
            <h2 className="title-sm bold">
                {isPending
                    ? <FormattedMessage id="account.axs.checkMail" />
                    : <FormattedMessage id="account.axs.addAccount" />
                }
            </h2>
            <p>
                {isPending
                    ? <FormattedMessage id="account.axs.checkMailDesc" />
                    : <FormattedMessage id="account.axs.addAccountDesc" />
                }
            </p>

            <form method="POST" onSubmit={handleFormSubmit} className={style.form}>
                <input type="hidden" name="ticketType" value={apiTicketMapping[teamType]} />
                <input type="hidden" name="callback" value={`${window.location.href}?selectedTeam=${teamId}`} />

                {isPending && (
                    <div className={style.form__link}>
                        <button type="button" className="cta-link" onClick={handleFormReset} aria-label={getFormattedMessage('account.axs.retry', lang)}>
                            <FormattedMessage id="account.axs.retry" />
                        </button>
                    </div>
                )}

                {!isPending &&
                    <>
                        <div className={`${style.form__input} form-group`}>
                            <label htmlFor="email"><FormattedMessage id="form.email" /><em>*</em></label>
                            <input type="text" id="email" name="email" autoComplete="email" required
                                   placeholder={getFormattedMessage('form.email', lang)} />
                        </div>

                        <div className={`${style.form__submit} form-group`}>
                            <button type="submit" className="cta-yellow" aria-label={getFormattedMessage('form.connect', lang)}>
                                <FormattedMessage id="form.connect" />
                            </button>
                        </div>

                        <div className={style.form__link}>
                            <a href={axsUrl} target="_blank" rel="noreferrer" className="cta-link" aria-label={getFormattedMessage('account.axs.createAccount', lang)}>
                                <FormattedMessage id="account.axs.createAccount" />
                            </a>
                        </div>
                    </>
                }

                {isError &&
                    <p className={style.form__error}>
                        <FormattedMessage id="form.errorGlobal" />
                    </p>
                }
            </form>
        </div>
    );
};

export default AxsEmailRegister;
